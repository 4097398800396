import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Vue } from "vue-property-decorator";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import Swal from "sweetalert2";
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");
import { Printd } from "printd";
import moment from "moment";

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfPageShoppingListComponent extends Vue {
  private tempValue: string = null;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get shoppingListSorterd(): Array<DfShoppingProduct> {
    let result: Array<DfShoppingProduct> = [...this.shoppingList].reverse();
    result = result.sort((itemA: any, itemB: any) => (itemA.checked && itemB.checked ? 0 : itemA.checked ? 1 : -1));

    return result;
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private toggleSelection(shoppingProduct: DfShoppingProduct) {
    this.shoppingList = this.shoppingList.map((product: DfShoppingProduct) => {
      product.checked = product.description === shoppingProduct.description ? !product.checked : product.checked;
      return product;
    });
  }

  private removeFromList(shoppingProduct: DfShoppingProduct) {
    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === shoppingProduct.description);
    if (index !== -1) {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }

  private addToList() {
    if (this.tempValue) {
      this.shoppingList = [new DfShoppingProduct(this.tempValue, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_to_wishlist",
        product: this.tempValue,
        origin: "digital_flyer",
      });
      this.tempValue = null;
    }
  }

  private removeShoppingList() {
    Swal.fire({
      title: this.$t("df-card-shopping-list.delete.title").toString(),
      text: this.$t("df-card-shopping-list.delete.body").toString(),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continua",
      cancelButtonText: "Annulla",
    }).then((result) => {
      if (result.value) {
        this.shoppingList = [];
      }
    });
  }

  private onClipboardSuccess(data: any) {
    const icon = data.event.target;
    const button = data.event.target.parentNode;
    if (icon != null) {
      icon.classList.toggle("far");
      icon.classList.toggle("fa-copy");
      icon.classList.toggle("fas");
      icon.classList.toggle("fa-check-double");
      button.setAttribute("title", "Copiato");
      setTimeout(() => {
        icon.classList.toggle("far");
        icon.classList.toggle("fa-copy");
        icon.classList.toggle("fas");
        icon.classList.toggle("fa-check-double");
        button.setAttribute("title", "Copia");
      }, 2000);
    }
    data.event.preventDefault();
  }

  get copyShoppingListValue() {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return bodyContent;
  }

  private printShoppingList() {
    const d = new Printd();
    const cssText = `
      .df-shopping-list-item-check,
      button {
        display: none;
      }

      .df-shopping-list-item {
        margin-bottom: 1rem;
      }
    `;
    d.print(document.getElementById("printable-area-shopping-page"), [cssText]);
  }

  get messengerShoppingList(): string {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `https://m.me/?text=${encodeURIComponent(bodyContent)}`;
  }

  get whatsappShoppingList(): string {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `https://wa.me/?text=${encodeURIComponent(bodyContent)}`;
  }

  get emailShoppingList(): string {
    const subjectContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}`;
    let bodyContent = ``;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `mailto:?subject=${subjectContent}&body=${encodeURIComponent(bodyContent)}`;
  }
}
